import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import ScrollingTitleComponent3 from '../Functions/Scrolltitle3';

const ParallaxContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
`;

const drawDash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const fillAnimation = keyframes`
  0%, 90% {
    fill: #bac736;
    fill-opacity: 0;
  }
  100% {
    fill: #bac736;
    fill-opacity: 1;
  }
`;

const AnimationContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextLine = styled.svg`
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  font-size: 80px;
  animation: ${drawDash} 5s linear forwards, ${fillAnimation} 5s ease-in forwards;
`;

const Text = styled.text`
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
`;

const ScrollAnimation = ({ isAnimated, setIsAnimated }) => {
  useEffect(() => {
    const handleScroll = () => {
      const animationContainer = document.querySelector('.animation-container');
      const rect = animationContainer.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isInView && !isAnimated) {
        setIsAnimated(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimated, setIsAnimated]);

  // ... (rest of the ScrollAnimation component)
};

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('bird.jpg');
  background-size: cover;
  background-position: center;
  transform: translateY(${props => props.offset}px);
  z-index: -1; // Add this line
`;

const ParallaxComponent = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const containerRef = useRef(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const rect = containerRef.current.getBoundingClientRect();
      const imageOffset = rect.top * -0.5; // Adjust the parallax speed here

      // Ensure the image stays fixed until it fully covers the container
      if (imageOffset < containerHeight - rect.height) {
        setScrollOffset(imageOffset);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ParallaxContainer ref={containerRef}>
      <AnimationContainer className="animation-container">
      <BackgroundImage offset={scrollOffset} />
        <ScrollAnimation isAnimated={isAnimated} setIsAnimated={setIsAnimated} />
        <ScrollingTitleComponent3 />
      </AnimationContainer>
    </ParallaxContainer>
  );
};

export default ParallaxComponent;