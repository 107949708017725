import React from 'react';
import styled from 'styled-components';

const Comp = styled.div`
  width: 100%;
  background: white;
`;

const GridContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 240px;
  max-width: 1800px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        grid-template-columns: repeat(1, 1fr);
        margin: auto;
        width: 96%;
        grid-gap: 60px;
    }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.5s;

  ${GridItem}:hover & {
    transform: scale(1.1);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s, ;
  transform: translateY(100%);
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${GridItem}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Text = styled.div`
  color: #1A1C26;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
`;

const images = [  {    url: 'foto4carousel.jpg',    title: 'Brain.ist', href: '#'   },  {    url: 'foto2carousel.jpg',    title: 'Hartog and Co',  },  {    url: 'foto3carousel.jpg',    title: 'Charadi Paris',  },  {    url: 'foto1carousel.jpg',    title: 'Other projects',  },];

const EnterWorld = () => {
  return (
    <Comp id='ourworld'>
      <GridContainer>
        {images.map((image, index) => (
          <GridItem key={index}>
            <Image src={image.url} alt={`Image ${index + 1}`} />
            <Overlay>
              <Text>{image.title}</Text>
            </Overlay>
          </GridItem>
        ))}
      </GridContainer>
    </Comp>
  );
};

export default EnterWorld;