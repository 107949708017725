import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollingTitle = styled.div`
  width: 100%;
  height: 20vh;
  overflow: hidden;
  position: relative;
`;

const TitleText = styled.div`
  font-size: 17vh;
  font-weight: bold;
  color: #00162A;
  white-space: nowrap;
  font-family: 'Josefin Sans', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(${props => props.progress * -0.6}%, 0, 0);
  :hover {
    color: transparent;
    -webkit-text-stroke: 0.01px #00162A;
  }
`;

const ScrollingTitleComponent2 = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const maxScroll = documentHeight - windowHeight;
    const progress = (scrollPosition / maxScroll) * 100;
    setScrollProgress(progress);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScrollingTitle>
      <TitleText progress={scrollProgress}>
      & MARKETING STRATEGISTS
      </TitleText>
    </ScrollingTitle>
  );
};

export default ScrollingTitleComponent2;