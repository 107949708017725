import React from 'react'
import {Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon, 
  NavMenu, 
  NavItem, 
  NavLinks,
  NavBtn,
  NavBtnLink,
  BarContainer,
  Logobar,
  LogoImg,
  BarContent,
  Bartext} from './NavbarElements'
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../Images/LogomiddelHert.png'
import styled from 'styled-components'
import { RiCustomerService2Line } from 'react-icons/ri';

const Navbar = ( { toggle } ) => {

const toggleHome = () => {
  scroll.scrollToTop();
};

  return (
    <>
    <Nav>
        <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
                <Logo src={logo} alt="logo" />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
              <NavLinks to="1997" smooth={true} duration={1900} spy={true} exact='true' offset={-300}>1997</NavLinks>
              </NavItem>
              <NavItem>
              <NavLinks to="ourworld" smooth={true} duration={1900} spy={true} exact='true' offset={-60}>Cases</NavLinks>
              </NavItem>
              <NavItem>
              <NavLinks to="Vakantie" smooth={true} duration={1900} spy={true} exact='true' offset={-100}>Design</NavLinks>
              </NavItem>
            </NavMenu>
            {/*<BarContent>
            <NavBtn>
              <NavBtnLink to="/Magazine">Login</NavBtnLink>
            </NavBtn>
  </BarContent> */}
        </NavbarContainer>
    </Nav>
    </>
  )
}

export const Logo = styled.img`
  height: 6vh;
  @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        height: 3vh;
    }
`

export default Navbar