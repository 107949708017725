import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import styled from 'styled-components';

const images = [
  'https://picsum.photos/id/1018/1000/1000',
  'https://picsum.photos/id/1019/1000/1000',
  'https://picsum.photos/id/1020/1000/1000',
  'https://picsum.photos/id/1021/1000/1000',
];

const Section = ({ shouldPauseScrolling }) => {
  const ref = useRef();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Configuring Intersection Observer API
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentImageIndex(0);
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    observer.observe(ref.current);

    return () => observer.unobserve(ref.current);
  }, []);

  // Updating currentImageIndex based on scroll
  useLayoutEffect(() => {
    const handleScroll = () => {
      if (!shouldPauseScrolling) {
        const { top, bottom } = ref.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top > 0 && bottom < windowHeight) {
          const index = Math.floor((top / windowHeight) * images.length);
          setCurrentImageIndex(index);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [shouldPauseScrolling]);

  return (
    <SectionContainer ref={ref}>
      <VideoContainer>
        <VideoImage src={images[currentImageIndex]} />
      </VideoContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  height: 100vh;
  width: 100%;
`;

const VideoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export default Section;
