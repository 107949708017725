import React from 'react';
import styled from 'styled-components';

const ProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7vh;
  max-width: 1300px;
  margin: auto;
`;

const ProjectCard = styled.div`
  width: 48%;
  margin-bottom: 2rem;
  border-radius: 5px;
  overflow: hidden;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
`;

const ProjectInfo = styled.div`
  padding: 1rem;
  color: #1A1C26;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1A1C26;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #1A1C26;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
`;

const ProjectComponent = styled.div`
    height: 60vh;
    width: 100%;
    display: block;
    
`

const projects = [  {    title: 'Hartog signet',    image: 'fotoring.png',    description: 'Our Hartog&Co signet ring is made with pure craftmanship, this ring celebrates our designers 12th year of being a designer.'  },  {    title: 'Wallet',    image: 'walletmockup.png',    description: 'Go and store your money with style inside our luxurious handcrafted wallet, safe and fits in every pocket.'  }];

const Projects = () => {
  return (
    <ProjectComponent>
    <ProjectContainer>
      {projects.map(project => (
        <ProjectCard key={project.title}>
          <ProjectImage src={project.image} alt={project.title} />
          <ProjectInfo>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
          </ProjectInfo>
        </ProjectCard>
      ))}
    </ProjectContainer>
    </ProjectComponent>
  );
};

export default Projects;