import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';


export const Nav = styled.nav`
    background: white;
    height: 15vh;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    padding-bottom: 0px;
    margin-bottom: 0px;
    z-index: 10;
    /* box-shadow: 0 10px 20px rgba(56, 125, 255, 0.27);  */

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        height: 7vh;
    }

`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 15vh;
    z-index: 1;
    width: 80%;
    padding: 0 18px;
    max-width: 1100px;
    @media screen and (max-width: 960px) {
        height: 7vh;
    }
`

export const NavLogo = styled(LinkRouter)`
    font-family: 'Roboto';
    color: white;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        color: #80ba26;
    }
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: black;

        &:hover {
            color: #4f74e3;
        }

        &:active {
            border-bottom: 3px solid;
            color: white;
        }
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavItem = styled.li `
    height: 15vh;
    background: white;
    @media screen and (max-width: 960px) {
        height: 7vh;
    }
 `

 export const NavLinks = styled(LinkS)`
    color: #00162A;
    font-family: 'Josefin Sans', sans-serif;
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 0 1rem;
    height: 100%;
    font-weight: 500;
    font-size: 9pt;
    cursor: pointer;

    /* &.active {
        border-bottom: 3px solid; 
        color: #80ba26;
    } */

    &:hover {
        border-bottom: 3px solid;
        color: #00162A;
    }
 `

 export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
        display: none;
    }
 `

 export const NavBtnLink = styled(LinkRouter)`
    border-radius: 50px;
    background: white;
    white-space: nowrap;
    padding: 18px 25px;
    color: #00162A;
    font-size: 16px;
    outline: none;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: linear-gradient(#85FFBD, #FFFB7D);
        border: 0;
        color: black;
    }
    `

export const Logobar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f5fc;
    width: 100%;
    height: 108.75px;
    @media screen and (max-width: 960px) {
        height: 7vh;
    }
`
export const BarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const LogoImg = styled.div`
    display: flex;
    background: transparent;
    height: 72.5px;
    width: 152px;
    background-size: cover;
    justify-self: flex-start;
    @media screen and (max-width: 960px) {
        height: 7vh;
    }
    `

export const BarContent = styled.div`
    color: #161a21;
    justify-self: flex-end;
    font-size: 25pt;
    justify-content: center;
    display: flex;
    align-items: center;
    vertical-align: center;

    @media screen and (max-width: 768px){
    display: none;
    }

`
export const Bartext =  styled(LinkS)`
    padding-left: 10px;
    color: #161a21;
    font-weight: bold;
    font-size: 12pt;
    cursor: pointer;
    &:hover {
        color: #98B4D4;
    }

    `