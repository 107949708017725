import React from 'react'
import {
    Opvangcontainer,
    OpvangWrapper,
    Opvangtitle,
    Opvangcontent,
    ButtonWrapper,
    Button, Fawrapper, BtnText,
} from './OpvangElements'

import {GiFrogFoot} from 'react-icons/gi'

const Opvang = () => {
  return (
    <Opvangcontainer id='Vakantie'>
        <OpvangWrapper>
        <Fawrapper></Fawrapper>
            <Opvangtitle>
            </Opvangtitle>
            <Opvangcontent>
                
            </Opvangcontent>
            <ButtonWrapper>
                <Button><BtnText href='https://wa.me/+31617737395' target='_blank'>Pre-order now</BtnText></Button>
            </ButtonWrapper>
        </OpvangWrapper>
    </Opvangcontainer>
  )
}

export default Opvang