import styled from 'styled-components'
import {Link} from 'react-router-dom'


export const OntdekkenContainer = styled.div`
    width: 100%;
    color: sadel;
    display: flex;
    align-items: center;
    height: 1100px;


@media screen and (max-width: 768px) {
    padding: 100px 0;
}



`



export const OntdekkenWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: 1000px;
    width: 100%;
    max-width: 1100px;
    display: grid;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 50vh;
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    };
`

export const OntdekkenRow = styled.div`
    background: white;
    background-size: cover;
    display: grid;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: solid 2px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    


    @media screen and (max-width: 768px) {
        grid-template-areas: 'col1' 'col2' 'col1 col1' 'col2 col2';
        height: 100%;
        width: 100%;
        border-radius: 20px;
    };
`


export const OntdekkenTitle = styled.h1`
    max-width: 540px;
    font-family: 'Josefin Sans';
    font-size: 20pt;
    font-weight: 300;
    /*color: #161a21; */
    color: #161a21; 
    text-align: center;
    padding-top: 60px;


    @media screen and (max-width: 768px) {
        color: white;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
    }
`

export const OntdekkenContent = styled.p`
    max-width: 540px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #161a21;
`

export const Videocontainer = styled.div`
    padding-top: 120px;
    display: flex;
    max-width: 1300px;
    height: 585px;
    width: 100%;
    justify-self: center;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    };

    :before {
        background: transparent;
        background-size: cover;
        z-index: 1;
    }
`

export const Video = styled.video`
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const ButtonWrapper = styled.div`
    display: grid;
    align-content: center;
    justify-content: center;
    color: white;
    padding-top: 20px;
    margin-bottom:  40px ;
`

export const Button = styled(Link)`
    background: transparent;
    font-family: 'Josefin Sans';
    background-size: cover;
    font-style: none;
    font-weight: 400;
    text-decoration: none; 
    border-radius: 40px;
    border: solid 2px;
    font-size: 12pt;
    padding: 25px 25px;
    color: #161a21;
    border-color: #161a21;
    :hover {
        color: white;
        background: #161a21;
    }
    `
