import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'
import {Link} from 'react-scroll'
import img1 from '../../Images/achtergrondboot.jpg'



export const HeroContainer = styled.div`
    background: white;
    background-size: cover; 
    background-position: center;
    margin-top: 0px;
    display: flex;
    align-items: flex;
    height: 85vh;
    width: 100%;
    position: relative;
    vertical-align: start;
    z-index: 1;


    
    /* Add: beforestyles */
    
    `

    // export const HeroBg = styled.div`
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;

    // `

export const CarouselWrapper = styled.div`
    background: transparent;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;


    .rec.rec-dot {
        background: white;
        box-shadow: 0 0 1px 3px #80ba26;
    }

    .rec.rec-dot:hover {
        background: #80ba26;
    }

    .rec.rec-dot:focus {
        background: #80ba26;
    }

    @media screen and (max-width: 680px) {
        padding-left: 0px;
        padding-right: 0px;
        display: none;
        .rec.rec-arrow {
            display: none;
        }
    };
    @media screen and (max-width: 980px) {
        padding-left: 0px;
        padding-right: 0px;
        display: none;
        .rec.rec-arrow {
            display: none;
        }
    };
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.27);
    border-radius: 50px; */

    /* styling carousel */

    .rec.rec-arrow {
        border-radius: 50%;
        color: #80ba26;
        border-color: #80ba26;
        border: 1px solid;
        background-color: white;
    }
    /* round buttons on hover */
    .rec.rec-arrow:hover {
        border-radius: 50%;
        color: white;
        background: #80ba26;
        border: 0;
    }
    /* hide disabled buttons */
        .rec.rec-arrow:disabled {
        visibility: hidden;
    }

    .rec.rec-arrow:focus { 
        color: white;
        background: #80ba26;
    }
/* disable default outline on focused items */
/* add custom outline on focused items */
    .rec-carousel-item:focus {
        outline: none;
        box-shadow: inset 0 0 1px 1px lightgrey;
    }
    `;

export const HeroContent = styled.div`
    z-index: 3;
    width: 100%;
    position: absolute;
    padding: 30px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    `



export const HeroContent2 = styled.div`
    width: 900px;
    padding-bottom: 60px;
    border-radius: 50px;
    padding-top: 0px;

    @media screen and (max-width: 1000px){
        width: 70%;
    }
`
export const HeroH1 = styled.h1`
    margin-top: 80px;
    color: white;
    font-size: 38px;
    text-align: center;
    text-shadow: 2px 0 6px black;

    
    @media screen and (max-width: 768px){
        font-size: 32px;
        margin-left: 30px;
        margin-right: 30px;
    }
    
    @media screen and (max-width: 480px){
        font-size: 22px;
        margin-left: 30px;
        margin-right: 30px;
    }
`

export const HeroP = styled.p`
    margin-top: 10px;
    color: white;
    font-size: 24px;
    text-align: center;
    padding: 1px 24px;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
    
    @media screen and (max-width: 480px){
        font-size: 12px;
    }
`

export const HeroBtnWrapper = styled.div `
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const Button = styled(Link)`
    border-radius: 50px;
    background: crimson;
    font-weight: bold;
    white-space: nowrap;
    padding: ${({big}) => (big ? '22px 58px' :  '22px 30px')};
    color: white;
    font-size: ${({fontBig}) => (fontBig ? '31px' : '26px')};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 5px 0px maroon;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #B01030;
    };
`
