import React, { useState, useEffect, useRef } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Set a minimum height to ensure the container covers the entire viewport */
  margin-bottom: 100px;
  margin-top: 100px;
`;

const TabContainer = styled.div`
  width: 50%;
`;

const StyledTabList = styled(TabList)`
    justify-content: center;
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ccc;
`;

const StyledTab = styled(Tab)`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 21pt;
  font-weight: 300;
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom: none;
  background-color: white;

  &:hover {
    border-bottom: 2px solid;
  }

  &.react-tabs__tab--selected {
    border-color: #ccc;
    border-radius: 5px 5px 0 0;
    background-color: #fff;
    border-bottom: 2px solid;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

const StyledTabPanelContainer = styled.div`
  position: relative;
  padding: 20px;
`;

const StyledTabPanel = styled(TabPanel)`
  font-size: 1.2rem;
  line-height: 2.6rem;
  margin-bottom: 2rem;
  color: #00162A;
  display: block; /* Add this line */
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  left: 0;
  right: 0;
  border: 0px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const TabComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  const tabTitles = ['Strategy', 'Design', 'Marketing']; // Add more as needed
  const tabContents = [
    'At Hartog & Co, we believe that a truly exceptional brand strategy is born from a delicate fusion of your company\'s aspirations and your customers\' desires. We understand that while your brand\'s goals are paramount, meeting the needs of your customers with purposeful products and services is what truly sets your brand apart. Our approach is centered around a deep understanding of these principles, and it is this synergy that forms the cornerstone of our strategy for every brand we work with.',
    'Design is the silent ambassador of your brand, conveying its essence and values through visual language. In the realm of luxury and class, every element, from typography and color palette to packaging and user interface, is meticulously crafted to resonate with the aspirations of your target audience. Subtle nuances, refined aesthetics, and a harmonious balance of form and function are the pillars upon which luxury design stands.\n\nOur approach transcends aesthetics alone; it delves deep into the psyche of your audience. We explore their desires, preferences, and aspirations, translating these insights into design elements that not only captivate but also resonate on a personal level. Every touchpoint, whether it\'s a website, product packaging, or promotional materials, becomes a canvas for crafting an immersive experience that embodies luxury and class.',
    'Marketing in the realm of luxury is an art of curation, not just promotion. We understand that luxury is not just a product; it\'s an emotion, a symbol of exclusivity that holds a unique place in the hearts of discerning customers. Through targeted storytelling and tailored campaigns, we build a narrative that connects with the aspirations of your audience, turning desire into demand.\n\nOur strategy focuses on creating a sense of anticipation and rarity. By carefully selecting the right channels and crafting campaigns that speak to the essence of your brand, we ensure that each interaction with your audience is a step towards building a lasting relationship. From exclusive previews to limited-edition releases, every marketing touchpoint is an invitation to be part of an exclusive world.',
  ]; // Add more as needed

  const panelContainerRef = useRef(null);
  const [maxPanelHeight, setMaxPanelHeight] = useState(0);

  useEffect(() => {
    // Calculate the maximum height of the panels
    const panelContainerHeight = panelContainerRef.current.clientHeight;
    setMaxPanelHeight(panelContainerHeight);
  }, []);

  return (
    <CenteredContainer style={{ height: maxPanelHeight + 'px' }}>
      <TabContainer>
        <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
          <StyledTabList>
            {tabTitles.map((title, index) => (
              <StyledTab key={index}>{title}</StyledTab>
            ))}
          </StyledTabList>
          <StyledTabPanelContainer ref={panelContainerRef}>
            {tabContents.map((content, index) => (
              <StyledTabPanel key={index} isVisible={index === selectedTab}>
                {content}
              </StyledTabPanel>
            ))}
          </StyledTabPanelContainer>
        </Tabs>
      </TabContainer>
    </CenteredContainer>
  );
};

export default TabComponent;