import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const appearAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

const SeparatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ isVisible }) => (isVisible ? '20px' : 0)};
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  position: relative;
`;

const Line = styled.div`
  width: 50%;
  max-width: 600px; /* You can adjust the max-width as needed */
  height: 0.5px;
  background-color: #002242; /* Change to your desired color */
  transform-origin: left center;
  transform: scaleX(${({ isVisible }) => (isVisible ? 1 : 0)});
  animation: ${appearAnimation} 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  position: relative;
`;



const LineSeparator = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const separator = document.getElementById('line-separator');
      if (separator) {
        const separatorTop = separator.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        setIsVisible(separatorTop - windowHeight <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <SeparatorWrapper isVisible={isVisible}>
      <Line id="line-separator" isVisible={isVisible}>
      </Line>
    </SeparatorWrapper>
  );
};

export default LineSeparator;