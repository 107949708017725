import React from 'react'
import {} from './footerelements'
import {FaInstagram, FaFacebook, FaDiscord, FaYoutube} from 'react-icons/fa'
import {FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap, SocialLogo, SocialIcons, SocialIconLink, WebsiteRights, Spline as Splines} from "./footerelements"

function Footer() {
  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/'>hartog&co</SocialLogo>
                    <WebsiteRights>hartog&co © 2022 All rights reserved.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="" target="_blank" aria-label="Facebook">
                            <FaFacebook />
                        </SocialIconLink>
                        <SocialIconLink href="" target="_blank" aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="//" target="_blank" aria-label="Youtube">
                            <FaYoutube />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer