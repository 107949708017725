import React from 'react'
import { SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SidebarBtnWrap, 
    SidebarRoute
} from './SidebarElements'

const Sidebar = ( {isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="1997" onClick={toggle}>1997</SidebarLink>
                <SidebarLink to="Vakantie" onClick={toggle}>products</SidebarLink>
                <SidebarLink to="story" onClick={toggle}>the story</SidebarLink>
                <SidebarLink to="contact" onClick={toggle}>services</SidebarLink>
            </SidebarMenu>
            <SidebarBtnWrap>
                <SidebarRoute to='/prijzen'>Shop</SidebarRoute>
            </SidebarBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
