import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import useRefs from 'react-use-refs'
import styled from 'styled-components'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
// import Sticky from '../components/Sidesticky';
// import styled from 'styled-components'
import Opvang from '../components/Opvang';
import Ontdekken from '../components/Ontdekken';
import Projects from '../components/Projects'
import AboutUs from '../components/Aboutus';
import EnterWorld from '../components/EnterWorld';
import LineSeparator from '../components/Functions/LineSeparator';
import TabComponent from '../components/Tabs/TabComponent';
import Marquee from '../components/Functions/Marquee';
import ScrollingTitleComponent from '../components/Functions/Scrolltitle';
import ScrollingTitleComponent2 from '../components/Functions/Scrolltitle2';
import ScrollingTitleComponent3 from '../components/Functions/Scrolltitle3';
import ParallaxComponent from '../components/Freedom/Parallax';


const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AboutUs />
      <ParallaxComponent />
      <EnterWorld />
      <Opvang />
      <Projects />
      <Footer />
    </>
  );
};



export default Home;