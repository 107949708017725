import React, { useState, Suspense } from 'react';
import { HeroContainer, HeroContent } from './HeroElements';
import Section from './Sectie'
import { Canvas } from '@react-three/fiber'
import { Loader, OrbitControls, Lightformer, PerspectiveCamera, Stars, Environment, PresentationControls,   Caustics, AccumulativeShadows, RandomizedLight, } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette
} from "@react-three/postprocessing";
import styled, { keyframes } from 'styled-components';

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const ScrollButtonContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: ${bounceAnimation} 1.5s infinite; /* Apply the animation here */
`;


const MouseIcon = styled.div`
  background: rgba(255,255,255, 0.3);
  width: 25px;
  height: 40px;
  border: 2px solid #000;
  border-radius: 25px;
  position: relative;
`;

const ScrollWheel = styled.div`
  width: 5px;
  height: 8px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
`;


const H1 = styled.h1`
  font-size: 15.3rem;
  color: #1A1C26;
  position: absolute;
  top: 15%;
  width: 100%;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 900;
  overflow: hidden;
  @media screen and (max-width: 1000px){
    display: none;
  }
`

function Model({ url }) {
  const gltf = useLoader(GLTFLoader, '/can.glb')
  return (
    <PresentationControls
  enabled={true} // the controls can be disabled by setting this to false
  global={false} // Spin globally or by dragging the model
  cursor={true} // Whether to toggle cursor style on drag
  snap={true} // Snap-back to center (can also be a spring config)
  speed={1} // Speed factor
  zoom={1} // Zoom factor when half the polar-max is reached
  rotation={[0, 0, 0]} // Default rotation
  polar={[0, Math.PI / 2]} // Vertical limits
  azimuth={[-Infinity, Infinity]} // Horizontal limits
  config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
>
    <primitive object={gltf.scene} scale={5.4}/>
    </PresentationControls>
  )
}

const HeroSection = () => {

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      <H1></H1>
      <Canvas dpr={[1.5, 2]} linear shadows>
        <Environment background={false} files="vatican_road_2k.hdr" />
      <fog color="#161616" attach="fog" near={8} far={30} />
      <Suspense fallback={null}>
          <Model  />
        </Suspense>
        <directionalLight position={[-10, 40, 10]} castShadow>
      </directionalLight>
      <EffectComposer multisampling={0} disableNormalPass={true}>
        <Bloom
          luminanceThreshold={0.1}
          luminanceSmoothing={2}
          height={500}
          opacity={3}
        />
        <Noise opacity={0.1} />
        <Vignette eskil={false} offset={0} darkness={0} />
      </EffectComposer>
        <ambientLight intensity={1} />
        <directionalLight position={[-10, 40, 10]} castShadow>
      </directionalLight>
        <PerspectiveCamera makeDefault position={[0, 0, 14]} fov={75}>
        </PerspectiveCamera>
        <OrbitControls autoRotate={false} enablePan={false} enableZoom={false} enableRotate={true} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
      </Canvas>
      <Loader />
    </HeroContainer>
  );
};

export default HeroSection;