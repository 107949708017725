import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

const scrollAnimation = keyframes`
  0%, 100% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeContainer = styled.div`
  background: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 20vh;
  overflow: hidden;
`;

const MarqueeTextWrapper = styled.div`
  display: flex;
  color: #00162A;
  animation: ${scrollAnimation} 60s linear infinite; /* Slower animation speed */

  &:hover {
    animation-play-state: paused;
    color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #00162A;
  }
`;

const MarqueeText = styled.span`
  white-space: nowrap;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20vh;
  margin-right: 10vw;
`;

const Marquee = ({ text, repeatCount }) => {
  return (
    <MarqueeContainer>
      <MarqueeTextWrapper>
        {Array.from({ length: repeatCount }).map((_, index) => (
          <MarqueeText key={index}>{text}</MarqueeText>
        ))}
      </MarqueeTextWrapper>
    </MarqueeContainer>
  );
};

export default Marquee;