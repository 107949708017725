import styled from 'styled-components'
import img from '../../Images/tshirtdesignversie7.jpg'

export const Opvangcontainer = styled.div`
    background: url(${img});
    background-color: white;
    background-size: cover;
    background-position:  center;
    height: 1100px;
    display: flex;
    align-items: center;
`
export const OpvangWrapper = styled.div`
    width: 100%;
    max-width: 1100px;
    height: 450px;
    display: grid;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    @media screen and (max-width: 768px){
        max-width: 80%;
    }
`

export const Opvangtitle = styled.h1`
    font-family: 'Josefin Sans';
    font-size: 40pt;
    font-weight: 200;
    color: white;
`

export const Opvangcontent = styled.p`
    font-size: 14pt;
    max-width: 500px;
    text-align: center;
    color: white;
`
export const ButtonWrapper = styled.div`
    display: grid;
    align-content: center;
    justify-content: center;
    color: white;
    padding-bottom: 20px;
    padding-top: 10px;
`

export const Button = styled.button`
    background: transparent;
    font-family: 'Josefin Sans';
    font-weight: bold;
    border-radius: 50px;
    border: 3px solid;
    font-size: 18pt;
    padding: 25px 25px;
    color: black;

    :hover {
        background: #1A1C26;
        color: white;
    }
    `

export const GiFrogFoot = styled.h2`
    font-size: 100px;
    display: flex;
    text-align: center;
    justify-self: center;
    color: white;

`

export const Fawrapper = styled.div`
    font-size: 60pt;
    text-align: center;
    justify-content: center;
    display: flex;
    color: white;

    :hover {
        color: #80ba26;
    }
    `


export const BtnText = styled.a`
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    color: #1A1C26;
    :hover {
        color: white;
    }

`