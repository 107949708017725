import React from 'react';
import styled from 'styled-components';

const AboutComponent = styled.div`
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        height: 100vh;
    }

`
const AboutContainer = styled.div`
  max-width: 1000px;
  margin: auto;
  text-align: center;
  @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

const AboutTitle = styled.h2`
  font-size: 2.6rem;
  margin-bottom: 2rem;
  color: #00162A;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
`;

const AboutDescription = styled.p`
  font-size: 1.2rem;
  line-height: 2.6rem;
  margin-bottom: 2rem;
  color: #00162A;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
`;

const AboutUs = () => {
  return (
    <AboutComponent id='1997'>
    <AboutContainer>
      <AboutTitle>Hartog & Co 1997</AboutTitle>
      <AboutDescription>
        Hartog & Co combines traditional craftmanship with the latest technology to create the ultimate experience for their clients. With projects running in Bel Air, LA, Mumbai, Cannes and Paris our mission is to present our unique and identical designs all over the world. The attention to detail and the strict deadlines, we truely are the best in the business. Our office is based in Rotterdam, with roots dating back to 1997. 
      </AboutDescription>
    </AboutContainer>
    </AboutComponent>
  );
};

export default AboutUs;