import React from 'react';
import {OntdekkenContainer, Button, ButtonWrapper, OntdekkenWrapper, OntdekkenRow, OntdekkenTitle, OntdekkenContent, Column1, Column2, Videocontainer, Video, } from './OntdekkenElements';
import styled from 'styled-components'

const Ontdekken = () => {
  return (
    <OntdekkenContainer id="ontdekken">
        <OntdekkenWrapper>
          <Videocontainer>
          <Foreground />
          <iframe width="1060" height="585" src="https://www.youtube.com/embed/a7GITgqwDVg" frameborder="0" allow="autoplay; gyroscope; picture-in-picture" allowfullscreen></iframe> 
          </Videocontainer>
            <OntdekkenRow>
                <OntdekkenTitle>Visit the Hartog&co art exhibition in Rotterdam</OntdekkenTitle>
                <ButtonWrapper>
                <Button>Get your tickets</Button>
                </ButtonWrapper>
                {/* <OntdekkenContent>Kom en ontdek verschillende soorten reptielen, amfibiëen en geleedpotigen in onze winkel. Leer meer over deze bijzondere dieren van onze specialisten.</OntdekkenContent> */}
            </OntdekkenRow>
        </OntdekkenWrapper>
    </OntdekkenContainer>
  )
}

export const Img1 = styled.img`
  height: 100%;
  width: 100%;
  background-size: cover;
`

export const Foreground = styled.img`
  height: 585px;
  width: 1060px;
  background-size: cover;
  z-index: 1;
  position: absolute;
  pointer-events: none;


`

export default Ontdekken